/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        $("header.menu").stick_in_parent({
	        //spacer:false
	      });
        $(".side-affix").stick_in_parent();
        window.sr = ScrollReveal();
				sr.reveal(document.querySelectorAll('.intro-appear'), {scale: 1, duration: 500, distance: '40px', easing : 'ease-in-out'},120);
				sr.reveal(document.querySelectorAll('.appear'), {scale: 1, duration: 500, distance: '40px', easing : 'ease-in-out'});
        $.stellar.positionProperty.essence_jobs = {
	        setPosition: function($element, newLeft, originalLeft, newTop, originalTop) {
	          if( ($element).hasClass("intro-parallax") ) {
	            newtransform = (-(newTop - originalTop));
	            if (newtransform<0) { newtransform = 0; }
	            $element.css('transform', 'translate3d(0 , ' + (newTop - originalTop) + 'px, ' + '0)');
	            newscroll = newTop/300;
	            if(newscroll>1) { newscroll = 1; }
	            //$element.css('transform', 'translate3d(' + newtransform + 'px, 0, 0)');
	            $element.css("opacity", 1 - newscroll); 
	          } else {
	            $element.css('transform', 'translate3d(0 , ' + (newTop - originalTop) + 'px, ' + '0)');
	          }
	        }
	      }
        
        $(window).stellar({
	        positionProperty: 'essence_jobs',
		      verticalOffset: 0,
	        horizontalScrolling: false,
	        hideDistantElements: false,
	        responsive: true
        });
        
        $("a.menu-mobile-toggle").click(function(e){
	        e.preventDefault();
	      	$("body").toggleClass("open-menu");  
        });
        
		    $('a.scrollto').bind('click', function(e) {
		      var $anchor = $(this);
		      if ($anchor.attr('data-offset')) { offset = $anchor.attr('data-offset') } else { offset = 0;}
		      $('html, body').stop().animate({ scrollTop: ( $($anchor.attr('href')).offset().top ) - offset }, 600);
		      e.preventDefault();
		    });
        
        //jQuery("section.intro h1").fitText(1.5);
        
        /*
        Barba.Pjax.start();
        
        Barba.Dispatcher.on('newPageReady', function(currentStatus, oldStatus, container, newPageRawHTML) {
					//alert(newPageRawHTML);
				});
        
        var FadeTransition = Barba.BaseTransition.extend({
				  start: function() {
				    Promise
				      .all([this.newContainerLoading, this.fadeOut()])
				      .then(this.fadeIn.bind(this));
				  },
				
				  fadeOut: function() {
					  $("section.intro").animate({ opacity: 0 }).promise();
				    return $(this.oldContainer).animate({ opacity: 0 }).promise();
				  },
				
				  fadeIn: function() {
				    var _this = this;
				    var $el = $(this.newContainer);
						
				    $(this.oldContainer).hide();
				    $("header").removeClass("fixed");
				    $("section.intro").hide();
				
				    $el.css({
				      visibility : 'visible',
				      opacity : 0
				    });
				
				    $el.animate({ opacity: 1 }, 400, function() {
				      _this.done();
				    });
				  }
				});
				
				
				Barba.Pjax.getTransition = function() {
				  return FadeTransition;
				};*/

        
        
        
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
